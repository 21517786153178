import React, { useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import { getTableTranslations } from './helpers/getTableTranslations';
export const MUI_TABLE_KEY =
  '89b678af1bd067f937618560b43d74ccTz04MTMzNSxFPTE3MzU1NjI3OTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

const Table = ({
  columns,
  rows,
  onPaginationModelChange,
  paginationModel,
  onFilterChange,
  onSortChange,
  rowCount,
  loading,
  rowHeigh = 34,
  checkboxSelection = false,
  disableRowSelectionOnClick = true,
  pagination = true,
  paginationMode = 'server',
  rowSelection = false,
  pageSizeOptions = [5, 10, 20, 30],
  autoPageSize = false,
  autoHeight = false,
  columnBuffer = 2,
  columnThreshold = 2,
  autoWidth = false,
  getRowHeight = () => 'auto',
  getEstimatedRowHeight = () => 100,
  filterMode = 'server',
  sortingMode = 'server',
  disableMultiSort = false,
  initialState = {},
  onRowDoubleClick = () => {},
  onColumnOrderChange = () => {},
  disableVirtualization = true,
}) => {
  const { t } = useAureliaI18n();
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      rowHeight={rowHeigh}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      pagination={pagination}
      paginationMode={paginationMode}
      onPaginationModelChange={onPaginationModelChange}
      rowCount={rowCount}
      rowSelection={rowSelection}
      paginationModel={paginationModel}
      onFilterModelChange={onFilterChange}
      onSortModelChange={onSortChange}
      pageSizeOptions={pageSizeOptions}
      autoPageSize={autoPageSize}
      autoHeight={autoHeight}
      loading={loading}
      columnBuffer={columnBuffer}
      columnThreshold={columnThreshold}
      autoWidth={autoWidth}
      getRowHeight={getRowHeight}
      getEstimatedRowHeight={getEstimatedRowHeight}
      filterMode={filterMode}
      sortingMode={sortingMode}
      disableMultiSort={disableMultiSort}
      initialState={initialState}
      onRowDoubleClick={onRowDoubleClick}
      disableVirtualization={disableVirtualization}
      onColumnOrderChange={onColumnOrderChange}
      slotProps={{
        pagination: {
          showFirstButton: true,
          showLastButton: true,
          labelDisplayedRows: ({ from, to, count }) =>
            `${from}-${to} ${t('muiTable.pageOf')} ${count}`,
          labelRowsPerPage: t('muiTable.linesCount'),
        },
      }}
      localeText={getTableTranslations(t)}
    />
  );
};

export default Table;
