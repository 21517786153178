import React from 'react';
import useChoice from '../../../react-hooks/useChoice';
import Popover from './helpers/Popover';
const Choice = ({ params, field }) => {
  const value = params?.value;
  const options = field?.options;

  if (!value || !options) return <div></div>;

  const choiceValue = useChoice({ type: options?.choice?.set, value: value });
  const colorClass = useChoice({
    type: options?.choice?.set,
    value: value,
    option: 'color',
  });
  const helpContent = useChoice({
    type: options?.choice?.set,
    value: value,
    option: 'help',
  });

  return (
    <>
      <span style={{ wordWrap: 'break-word' }} className={colorClass}>
        {choiceValue}
      </span>
      {helpContent && <Popover content={helpContent} />}
    </>
  );
};

export default Choice;
