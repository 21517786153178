import React from 'react';

const Date = ({ params, field }) => {
  const value = params.value;
  const options = field?.options;

  if (!value) {
    return '';
  }

  const formatDate = (date) => {
    return moment(date)
      .tz(options?.timezone || 'UTC')
      .format(options?.format || 'L LT');
  };

  return (
    <>
      {options?.multiple ? (
        <div>
          {value.map((date, index) => (
            <div key={index}>{formatDate(date)}</div>
          ))}
        </div>
      ) : (
        <div>{formatDate(value)}</div>
      )}
    </>
  );
};

export default Date;
