import React from 'react';
import Autocomplete from '../../../form/react/fields/Autocomplete';
export const ChoiceFilter = (props) => {
  const { item, applyValue, field } = props;

  const onChange = (items) => {
    applyValue({ ...item, value: items });
  };
  return (
    <div style={{ marginTop: '3px' }}>
      <Autocomplete
        field={{
          ...field,
          modelId: field?.modelId || field?.options?.modelId,
          multiple: true,
        }}
        onChange={onChange}
        value={item?.value || ''}
        size={'small'}
        variant={'standard'}
      />
    </div>
  );
};
