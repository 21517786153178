// aurelia-cell-renderer.js
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Container } from 'aurelia-framework';
import { ViewCompiler, ViewResources } from 'aurelia-templating';
import { v4 as uuidv4 } from 'uuid';

export const compileAndRenderAureliaTemplateInReact = (
  element,
  template,
  viewModel
) => {
  const container = Container.instance;
  const viewCompiler = container.get(ViewCompiler);
  const resources = container.get(ViewResources);

  const viewFactory = viewCompiler.compile(template, resources);
  const view = viewFactory.create(container);

  view.bind({ value: viewModel });
  view.appendNodesTo(element);
  view.attached();
  return view;
};

const AureliaCellRenderer = ({ template, data }) => {
  const aureliaHost = useRef(null);
  const viewRef = useRef(null);

  const deAttach = () => {
    viewRef.current.detached();
    viewRef.current.unbind();
    viewRef.current = null;
  };
  useEffect(() => {
    if (aureliaHost.current) {
      viewRef.current = compileAndRenderAureliaTemplateInReact(
        aureliaHost.current,
        template,
        data
      );
    }

    return () => {
      if (viewRef.current) {
        deAttach();
      }
    };
  }, [template, data]);

  return <div ref={aureliaHost}></div>;
};

export const renderAureliaCell = (params, field) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <AureliaCellRenderer
      key={params.rowIndex + params.value + id}
      data={params.value}
      template={`<template>${field.template}</template>`}
    />
  );
};
