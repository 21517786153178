import React, { useState, useEffect } from 'react';

import { Nl2BrValueConverter } from '../../../value-converter/nl2br-value-converter';

const Multiline = ({ params, field }) => {
  const value = params?.value;
  const nl2Br = new Nl2BrValueConverter();
  const [clipped, setClipped] = useState(false);

  const handleClick = () => {
    setClipped(false);
  };

  const ContentDiv = "div";
  const ContentPre = "pre";

  if (!field?.pre) {
    return (
      <ContentDiv
        className="multiline-content"
        clipped={clipped}
        dangerouslySetInnerHTML={{ __html: nl2Br.toView(value) }}
        onClick={handleClick}
      />
    );
  } else {
    return (
      <ContentPre
        className="multiline-content"
        clipped={clipped}
        onClick={handleClick}
      >
        {value}
      </ContentPre>
    );
  }
};

export default Multiline;
