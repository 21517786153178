import React from 'react';
import { Popover, Typography } from '@mui/material';
import { formatCurrency } from '../../../toma-mask/fast-booking/components/functions/fucntions';

const Money = ({ params, field }) => {
  const value = params?.value;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="number-display">
      {formatCurrency(value)}
      {value?.currencies?.length > 0 && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }} style={{ color: '#bd4241' }}>
            {value.currencies.map((price) => (
              <div key={price}>{formatCurrency(price)}</div>
            ))}
          </Typography>
        </Popover>
      )}
    </div>
  );
};

export default Money;
