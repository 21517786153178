import React from 'react';
import SioBicInfo from './helpers/SioBicInfo';

export const Bic = ({ params }) => {
  const value = params.value;
  return (
    <>
      {value && <span>{value}</span>}
      <SioBicInfo value={value} />
    </>
  );
};

export default Bic;
