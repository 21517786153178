import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import { useAureliaI18n } from '../../../../react-hooks/useAureliaI18n';

const Popover = ({ content, iconClass = "fa fa-circle-question" }) => {
  const { t } = useAureliaI18n
  const popoverRef = useRef(null);

  useEffect(() => {
    const $popover = $(popoverRef.current);

    $popover.popover({
      content: () => t(content),
      container: 'body',
      placement: 'auto right',
      trigger: 'hover',
      html: true,
      sanitize: false,
    });

    return () => {
      $popover.popover('dispose');
    };
  }, [content, t]);

  return (
    <span ref={popoverRef} className={iconClass}></span>
  );
};

export default Popover;