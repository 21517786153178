import { DateFilter } from './DateFilter';
import TextFilter from './TextFilter';
import { BooleanFilter } from './BooleanFilter';
import NumberFilter from './NumberFilter';
import { ChoiceFilter } from './ChoiceFilter';
import {
    getTranslation,
    useAureliaI18n,
} from '../../../react-hooks/useAureliaI18n';

export const isFilterable = (field) => {
    if (!field.searchable) {
        return;
    }

    return true;
};

export const filterOperators = (field) => {
    const t = getTranslation;

    switch (field.filter || field.type) {
        case 'email':
        case 'collection':
        case 'string':
        case 'translatable':
        case 'multiline':
        case 'file-path':
        case 'url':
            return [
                {
                    label: t('muiTable.filterOperatorContains'),
                    value: 'contains',
                    InputComponent: TextFilter,
                },
                {
                    label: t('muiTable.filterOperatorNotContains'),
                    value: 'notContains',
                    InputComponent: TextFilter,
                },
                {
                    label: t('muiTable.filterOperatorIsEmpty'),
                    value: 'isEmpty',
                },
                {
                    label: t('muiTable.filterOperatorIsNotEmpty'),
                    value: 'isNotEmpty',
                },
            ];
        case 'money':
            return [
                {
                    label: t('muiTable.filterOperatorRange'),
                    value: 'integer-combined',
                    InputComponent: NumberFilter,
                    InputComponentProps: { field },
                },
            ];
        case 'integer':
        case 'float':
            return [
                {
                    label: t('muiTable.filterOperatorRange'),
                    value: 'integer-combined',
                    InputComponent: NumberFilter,
                    InputComponentProps: { field },
                },
            ];

        case 'dueDate':
        case 'age':
        case 'date':
            return [
                {
                    label: t('muiTable.filterOperatorDateRange'),
                    value: 'dateRange',
                    InputComponent: DateFilter,
                    InputComponentProps: { type: 'date' },
                },
            ];

        case 'boolean':
            return [
                {
                    label: t('muiTable.filterOperatorIs'),
                    operator: 'exact',
                    value: 'booleanExact',
                    InputComponent: BooleanFilter,
                },
            ];

        case 'choice':
        case 'reference':
            return [
                {
                    label: t('muiTable.filterOperatorIn'),
                    operator: 'choice',
                    value: 'choice',
                    InputComponent: ChoiceFilter,
                    InputComponentProps: {
                        field,
                    },
                },
                {
                    label: t('muiTable.filterOperatorNotIn'),
                    operator: 'NiNchoice',
                    value: 'NiNchoice',
                    InputComponent: ChoiceFilter,
                    InputComponentProps: {
                        field,
                    },
                },
            ];
        case 'address':
            return [
                {
                    label: t('muiTable.filterOperatorContains'),
                    value: 'textContains',
                    InputComponent: TextFilter,
                },
            ];
        case 'phone':
            return [
                {
                    label: t('muiTable.filterOperatorContains'),
                    value: 'phone',
                    InputComponent: TextFilter,
                },
            ];

        case 'tags':
        case 'percentage':
        case 'physical-size':
            return [];
    }

    return [];
};
