import React from 'react';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import useChoice from '../../../react-hooks/useChoice';
// import { GoogleMapsLink } from '../../../../view-block/google-maps-link/google-maps';

const SingleAddress = ({ address, options, index }) => {
  if (!address) {
    return <></>;
  }
  const { t } = useAureliaI18n();
  const isFirst = index == 0;
  const salutation = useChoice({
    type: 'letterSalutation',
    value: address?.salutation,
  });
  const country = useChoice({ type: 'country', value: address?.country });

  // TODO Google MAP Link
  return (
    <>
      {/* <GoogleMapsLink address={address} /> */}
      {options?.isSingleLine ? (
        address?.formattedAddress && <span>{address?.formattedAddress}</span>
      ) : (
        <>
          {isFirst && !address?.addressType && (
            <>
              ({t(`sio.field.default`)})
              <br />
            </>
          )}
          {isFirst && address?.addressType && (
            <>
              ({address.addressType})
              <br />
            </>
          )}
          {address.lastName && (
            <>
              {`${salutation}`} {address.title} {address.firstName}{' '}
              {address.lastName}
              <br />
            </>
          )}
          {address?.company && (
            <>
              {address.company}
              <br />
            </>
          )}
          {address?.addressType !== 'poBox' ? (
            <>
              {address?.addressLine1 && (
                <>
                  {address?.addressLine1}
                  <br />
                </>
              )}
              {address?.addressLine2 && (
                <>
                  {address?.addressLine2}
                  <br />
                </>
              )}
              {address?.zip && <>{address.zip}</>}
              {address?.city && (
                <>
                  {' '}
                  {address.city}
                  <br />
                </>
              )}
            </>
          ) : (
            <>
              {address.poBox && (
                <>
                  {address.poBox}
                  <br />
                </>
              )}
              {address.poBoxZip && <>{address.poBoxZip}</>}
              {address.poBoxLocation && (
                <>
                  {address.poBoxLocation}
                  <br />
                </>
              )}
            </>
          )}
          {`${country}`}
        </>
      )}
    </>
  );
};
const Address = ({ params, field }) => {
  const value = params.value;
  const options = field?.options;

  if (options.many && value) {
    return (
      <>
        {value.map((item, i) => (
          <React.Fragment key={i}>
            <SingleAddress address={item} options={options} index={i} />
            <hr />
          </React.Fragment>
        ))}
      </>
    );
  } else if (!options?.many && value) {
    return <SingleAddress address={value} options={options} index={i} />;
  }

  return null;
};

export default Address;
