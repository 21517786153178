import React, { useState, useCallback } from 'react';
import { TextField, FormControl, Box } from '@mui/material';
import debounce from 'debounce-promise';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const NumberFilter = (props) => {
  const { item, applyValue, field } = props;
  const [fromValue, setFromValue] = useState(item.value?.$gte || '');
  const [toValue, setToValue] = useState(item.value?.$lte || '');
  const { t } = useAureliaI18n();

  const debouncedApplyValue = useCallback(
    debounce(({ from, to }) => {
      let value = {};
      if (field.type == 'money') {
        from = from && from * 100;
        to = to && to * 100;
      }
      if (from && !to) {
        value = { $gte: from };
      }
      if (to && !from) {
        value = { $lte: from };
      }

      if (from && to) {
        value = { $gte: from, $lte: to };
      }
      applyValue({ ...item, fieldType: field.type, value });
    }, 500),
    [item, applyValue]
  );

  const handleFromChange = (event) => {
    const newValue = event.target.value;
    setFromValue(newValue);
    debouncedApplyValue({ from: newValue, to: toValue });
  };

  const handleToChange = (event) => {
    const newValue = event.target.value;
    setToValue(newValue);
    debouncedApplyValue({ from: fromValue, to: newValue });
  };

  return (
    <FormControl>
      <Box display="flex" gap={1} sx={{ marginTop: '8px', marginLeft: '8px' }}>
        <TextField
          variant="outlined"
          value={fromValue}
          onChange={handleFromChange}
          type="text"
          size="small"
          label={t("muiTable.filterValueFrom")}
        />
        <TextField
          variant="outlined"
          value={toValue}
          onChange={handleToChange}
          type="text"
          size="small"
          label={t("muiTable.filterValueTo")}
        />
      </Box>
    </FormControl>
  );
};

export default NumberFilter;
