import React, { useMemo } from 'react';
import moment from 'moment';

export const DueDate = React.memo(({ params, field }) => {
  const value = params.value;

  if (!value) {
    return <></>;
  }

  const getClass = () => {
    let now = moment();
    let diff = moment(value).diff(now);

    if (diff < 0) {
      return 'text-danger';
    } else if (diff < 86400 * 3 * 1000) {
      return 'text-warning';
    }

    return '';
  };

  return (
    <span className={getClass()}>
      {moment(value).format('L')}
      {value.delay && ` (+${value.delay})`}
    </span>
  );
});

export default DueDate;
