import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

export const stringToBoolean = (str) => {
  if (str === 'true') return true;
  if (str === 'false') return false;
  return str;
};

export const BooleanFilter = (props) => {
  const { item, applyValue } = props;
  const { t } = useAureliaI18n();
  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div style={{ marginTop: '19px' }}>
      <Select
        value={item.value || ''}
        onChange={handleFilterChange}
        displayEmpty
        size="small"
        sx={{ width: '100%' }}
      >
        <MenuItem value="">{t('muiTable.filterValueAny')}</MenuItem>
        <MenuItem value={'true'}>{t('muiTable.filterValueTrue')}</MenuItem>
        <MenuItem value={'false'}>{t('muiTable.filterValueFalse')}</MenuItem>
      </Select>
    </div>
  );
};
