import { stringToBoolean } from './filters/BooleanFilter';

export const createMongoDBCondition = (filterModel) => {
    const conditions = [];
    let query;

    for (const item of filterModel.items) {
        if (
            (item.operator !== 'isEmpty' &&
                item.operator !== 'isNotEmpty' &&
                !item.value) ||
            (Array.isArray(item?.value) && item?.value?.length === 0)
        ) {
        } else {
            let condition;

            switch (item.operator) {
                case 'contains':
                    condition = {
                        [item.field]: {
                            $match: `${item.value}`,
                        },
                    };
                    break;
                case 'notContains':
                    const possibleMatches = [
                        item.value,
                        new RegExp(`^${item.value}`), // Starts with
                        new RegExp(`${item.value}$`), // Ends with
                        new RegExp(`^${item.value}$`), // Exact match
                    ];
                    condition = { [item.field]: { $nin: possibleMatches } };
                    break;
                case 'equals':
                    condition = { [item.field]: item.value };
                    break;
                case 'startsWith':
                    condition = { [item.field]: { $match: `^${item.value}` } };
                    break;
                case 'endsWith':
                    condition = { [item.field]: { $match: `${item.value}$` } };
                    break;
                case 'isEmpty':
                    condition = {
                        $or: [
                            { [item.field]: { $exists: false } },
                            { [item.field]: null },
                            { [item.field]: '' },
                        ],
                    };
                    break;
                case 'isNotEmpty':
                    condition = {
                        $and: [
                            { [item.field]: { $exists: true } },
                            { [item.field]: { $ne: null } },
                            { [item.field]: { $ne: '' } },
                        ],
                    };
                    break;
                case 'isAnyOf':
                    condition = { [item.field]: { $in: item.value } };
                    break;
                case 'booleanExact':
                    const value = stringToBoolean(item.value);
                    if (value) {
                        condition = {
                            [item.field]: { $eq: true },
                        };
                    } else {
                        condition = {
                            [item.field]: { $ne: true },
                        };
                    }
                    break;
                case 'textContains':
                    condition = {
                        $text: {
                            $match: `${item.value}`,
                        },
                    };
                    break;
                case 'phone':
                    condition = {
                        [item.field]: {
                            $phone: `${item.value}`,
                        },
                    };
                    break;
                case 'dateRange':
                    condition = {};
                    if (item.value[0] && item.value[1]) {
                        condition[item.field] = {
                            $gte: item.value[0],
                            $lte: item.value[1],
                        };
                    } else if (item.value[0]) {
                        condition[item.field] = { $gte: item.value[0] };
                    } else if (item.value[1]) {
                        condition[item.field] = { $lte: item.value[1] };
                    }
                    break;
                case 'integer-combined':
                    if (item.fieldType == 'money') {
                        condition = { [`${item.field}.amount`]: item.value };
                    } else {
                        condition = { [item.field]: item.value };
                    }
                    break;
                case 'choice':
                    condition = {
                        [item.field]: {
                            $in: item.value.map((v) => v?.value || v),
                        },
                    };
                    break;
                case 'NiNchoice':
                    condition = {
                        [item.field]: {
                            $nin: item.value.map((v) => v?.value || v),
                        },
                    };
                    break;
            }

            if (condition) {
                conditions.push(condition);
            }
        }
    }

    if (filterModel.logicOperator === 'and') {
        query = { $and: conditions };
    } else if (filterModel.logicOperator === 'or') {
        query = { $or: conditions };
    }

    // Add quick filter condition if present
    if (filterModel.quickFilterValues.length > 0) {
        const quickFilterCondition = {
            $or: filterModel.quickFilterValues.map((value) => ({
                $or: Object.keys(yourSchema).map((field) => ({
                    [field]: { $regex: value, $options: 'i' },
                })),
            })),
        };
        if (query) {
            query = { $and: [query, quickFilterCondition] };
        } else {
            query = quickFilterCondition;
        }
    }

    return query;
};
