import React from 'react';
import _ from 'lodash';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const Email = ({ params }) => {
  // todo implement sio action component
  const value = params.value;

  const { t } = useAureliaI18n();

  const unifyValue = (value) => {
    if (value == null || value === '') {
      return [];
    }

    if (_.isString(value)) {
      value = [value];
    }

    return value.map((email) => (_.isString(email) ? { email } : email));
  };

  const getType = (type) => {
    if (type === 'private') {
      return t('base.email.private');
    } else if (type === 'business') {
      return t('base.email.business');
    }
    return '';
  };

  const unifiedValue = unifyValue(value);

  return (
    <div
      className="list-without-space"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      {unifiedValue.map((entry, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
            marginBottom: '4px',
          }}
        >
          <span style={{ wordBreak: 'break-word', maxWidth: '100%' }}>
            {entry.email}
            {entry.type && ` (${getType(entry.type)})`}
            {index === 0 &&
              unifiedValue.length > 1 &&
              ` (${t('sio.field.default')})`}
          </span>
          {/* {column.email && (
            <SioAction
              config={{
                buttonClass: 'btn btn-link btn-link-inline',
                icon: 'fa fa-envelope',
                type: 'documents-preview',
                previewGeneratorId: 'email/send',
                previewPreset: 'email',
              }}
              context={{ id: item.id, modelId: item.modelId }}
            />
          )} */}
          {index !== unifiedValue.length - 1 && (
            <span className="separator">, </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Email;
