import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserContentLocale } from '../../../store/store-slices/userSettingsSlice';

const Translateable = ({ params, field, locale }) => {
  const userContentLocale = useSelector(selectUserContentLocale);

  const [value, setValue] = useState(params?.value);
  const [currentValue, setCurrentValue] = useState([]);
  const [clipped, setClipped] = useState(true);

    useEffect(() => {
      const initialValue = params?.value;
      setValue(initialValue);
      setValueForLocale(userContentLocale, field?.history);
    }, [userContentLocale]);


    const setValueForLocale = useCallback(
      (locale, history) => {
        const multiple = field?.options?.multiple;
        const originalValue = multiple ? value : value ? [value] : [];
        let newValue = [];

        for (const val of originalValue ?? []) {
          if (history) {
            for (const localeValue in val) {
              if (val.hasOwnProperty(localeValue)) {
                newValue.push(localeValue + ': ' + val[localeValue]);
              }
            }
          } else {
            if (val[locale] != null) {
              newValue.push(val[locale]);
            }
          }
        }
        setCurrentValue(newValue);
      },
      [field, userContentLocale]
    );

    const handleSetClipped = useCallback((isClipped) => {
      setClipped(isClipped);
    }, []);

    const nl2Br = (str) => {
      return str.replace(/\n/g, '<br />');
    };

    const clipClass = (content) => {
      return clipped ? 'clipped' : '';
    };

  return (
    <>
      {currentValue.map((value, index) => (
        <React.Fragment key={index}>
          {field?.options?.multiline ? (
            <span
              className={`multiline-content ${clipClass(value)}`}
              dangerouslySetInnerHTML={{ __html: nl2Br(value) }}
              onClick={handleSetClipped}
            />
          ) : (
            <span
              className={`multiline-content ${clipClass(value)}`}
              dangerouslySetInnerHTML={{ __html: value }}
              onClick={handleSetClipped}
            />
          )}
          {index !== currentValue.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export default Translateable;
