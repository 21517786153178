import React, { memo, useEffect, useState } from 'react';
import { ModelLabelService } from '../../../reference/model-label-service';
import { Container } from 'aurelia-framework';

export const ReferenceDisplay = memo(function ReferenceDisplay(props) {
  let modelLabelService = Container.instance.get(ModelLabelService);
  const { reference, field } = props;

  const arrReference = Array.isArray(reference) ? reference[0] : reference;
  if (!arrReference?.id || field?.options?.modelId == 'file/file') {
    return <></>;
  }

  const [link, setLink] = useState(null);

  useEffect(() => {
    let isMounted = true;

    modelLabelService.getReferenceLinks(arrReference, false).then((result) => {
      if (isMounted) {
        setLink(result);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [reference]);

  return (
    <>
      {link && link.linkParams ? (
        <a
          href={
            '/view/' +
            link.linkParams.moduleId +
            '/' +
            link.linkParams.viewId +
            '?id=' +
            link.linkParams.id
          }
          target="blank"
        >
          {props.children ? props.children : link.label}
        </a>
      ) : (
        <></>
      )}
      {link && !link.linkParams ? (
        <>{props.children ? props.children : link.label}</>
      ) : (
        <></>
      )}
      {!link && props.children ? <>{props.children}</> : <></>}
    </>
  );
});
