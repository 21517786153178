import React from 'react';
import { swiftInfo } from '../../../../store/api';
const { initiate, select } = swiftInfo;

const SioBicInfo = ({ value }) => {
  const { isLoading, data = [] } = select(this.value)(getState());
  this.loading = isLoading;
  this.bank = data[0];
  this.city = data[1];
  this.country = data[2];

  if (!loading && !bank) return null;

  return (
    <div style={{ fontSize: '80%' }}>
      {loading && <i className="fa fa-spinner fa-pulse fa-fw"></i>}
      {bank && (
        <span>
          {bank}, {city}, {country}
        </span>
      )}
    </div>
  );
};

export default SioBicInfo;
