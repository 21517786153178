import React, { useMemo } from 'react';
import moment from 'moment';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

export const Age = React.memo(({ params, options }) => {
  const value = params.value;
  const { t } = useAureliaI18n();

  if (!value) {
    return <></>;
  }
  const getAge = (date) => {
    return moment().diff(moment(date), 'years');
  };

  return (
    <div>
      {moment(value).format('L')}
      {value && (
        <span>
          {' '}
          ({t('sio.field.age')}: {getAge(value)})
        </span>
      )}
    </div>
  );
});

export default Age;
