import React from 'react';

const Boolean = ({ params }) => {
  return params.value ? (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-booleanCell"
      focusable="false"
      role="img"
      viewBox="0 0 24 24"
      data-testid="CheckIcon"
      data-value="true"
      style={{maxWidth:"20px"}}
    >
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      <title>yes</title>
    </svg>
  ) : (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-booleanCell"
      focusable="false"
      role="img"
      viewBox="0 0 24 24"
      data-testid="CloseIcon"
      data-value="false"
      style={{maxWidth:"20px"}}
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      <title>no</title>
    </svg>
  );
};

export default Boolean;
