import Address from './Address';
import Age from './Age';
import { Bic } from './Bic';
import Choice from './Choice';
import Collection from './Collection';
import Date from './Date';
import Email from './Email';
import Multiline from './Multiline';
import Phone from './Phone';
import { ReferenceDisplay } from './Reference';
import Translateable from './Translateable';
import Url from './Url';
import Boolean from './Boolean';
import Money from './Money';
import { renderAureliaCell } from './AureliaCell';
import DueDate from './DueDate';

export const getFieldRenderer = (field) => {
    if (!field.type && !field.template) {
        return (params) => {
            return <span>{params?.value}</span>;
        };
    }
    if (!field.type && field.template) {
        return (params) => {
            return renderAureliaCell(params, field);
        };
    }
    // console.log(field, 'field');

    switch (field.type) {
        case 'string':
            return (params) => {
                return <span>{params?.value}</span>;
            };
        case 'email':
            return (params) => {
                return <Email params={params} />;
            };
        case 'address':
            return (params) => {
                return <Address field={field} params={params} />;
            };
        case 'reference':
            return (params) => {
                return (
                    <ReferenceDisplay reference={params?.value} field={field} />
                );
            };

        case 'phone':
            return (params) => {
                return <Phone params={params} />;
            };
        case 'collection':
            if (field.property == 'phones') {
                return (params) => {
                    return <Phone params={params} />;
                };
            } else {
                return (params) => {
                    return <Collection params={params} field={field} />;
                };
            }
        case 'date':
            return (params) => <Date params={params} field={field} />;
        case 'number':
            return (params) => <span>{params?.value?.toFixed(2)}</span>;
        case 'boolean':
            return (params) => {
                return <Boolean params={params} />;
            };
        case 'translatable':
            return (params) => {
                return <Translateable params={params} field={field} />;
            };
        case 'bic':
            return (params) => {
                return <Bic params={params} field={field} />;
            };
        case 'age':
            return (params) => {
                return <Age params={params} field={field} />;
            };
        case 'choice':
            return (params) => {
                return <Choice params={params} field={field} />;
            };
        case 'opening-times':
            return (params) => {
                return <div>opening-times</div>;
            };
        case 'color':
            return (params) => {
                return <div>sio-cell-color</div>;
            };
        case 'dueDate':
            return (params) => {
                return <DueDate params={params} field={field} />;
            };
        case 'url':
            return (params) => {
                return <Url params={params} />;
            };

        case 'file-path':
            return (params) => {
                return <div>file-path</div>;
            };
        case 'file':
            return (params) => {
                return <div>file</div>;
            };
        case 'money':
            return (params) => {
                return <Money params={params} field={field} />;
            };
        case 'percentage':
            return (params) => {
                return <div>percentage</div>;
            };
        case 'price-charge':
            return (params) => {
                return <div>price-charge</div>;
            };
        case 'military-time':
            return (params) => {
                return <div>military-time</div>;
            };
        case 'date-interval':
            return (params) => {
                return <div>date-interval</div>;
            };
        case 'multiline':
            return (params) => {
                return <Multiline params={params} />;
            };
        case 'codemirror':
            return (params) => {
                return <div>codemirror</div>;
            };
        case 'time':
            return (params) => {
                return <div>time</div>;
            };
        case 'price-set':
            return (params) => {
                return <div>price-set</div>;
            };

        case 'translated':
            return (params) => {
                return <div>translated</div>;
            };
        case 'date-range':
            return (params) => {
                return <div>date-range</div>;
            };
        default:
            return (params) => {
                return <div>default-cell</div>;
            };
    }
};
