import React from 'react';
import PhoneDisplay from './helpers/PhoneDisplay';

export const Phone = ({ params }) => {
  const values = Array.isArray(params.value) ? params.value : [params.value];

  return (
    <div className="sio-cell-phone">
      {values.map((value, index) =>
        value?.phone ? (
          <React.Fragment key={index}>
            <PhoneDisplay value={value?.phone} />
            {index !== values.length - 1 && <br />}
          </React.Fragment>
        ) : (
          <></>
        )
      )}
    </div>
  );
};

export default Phone;
