import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const Url = ({ params }) => {
  const unifyValue = (val) => {
    if (_.isString(val)) {
      return [val];
    }
    return val;
  };

  const value = unifyValue(params?.value);

  const getLabel = (val) => {
    return val.replace(/(^\w+:|^)\/\//, '');
  };

  return (
    <div className="list-without-space">
      {value?.length > 0 &&
        value?.map((entry, index) => (
          <React.Fragment key={index}>
            <a href={entry} target="_blank" rel="noopener noreferrer">
              {getLabel(entry)}
            </a>
            {index !== value.length - 1 && (
              <span className="separator">, </span>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default Url;
