import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useChoice from '../../../react-hooks/useChoice';

const Collection = ({ params, field }) => {
  let value = params?.value;
  const options = field?.options;
  const choices = useChoice({ type: options?.choice?.set, getAll: true });

  const defaultOptions = {
    separator: ', ',
    entryTemplate: '${ entry }',
  };

  if (value) {
    if (_.isObject(value) && !_.isArray(value)) {
      value = [value];
    }
  }

  const renderEntry = (entry, index) => {
    const renderedEntry = options?.entryTemplate?.replace('${ entry }', entry);
    if (choices?.length > 0) {
      return (
        <React.Fragment key={index}>
          <span>{choices.find((c) => c.value == entry)?.label}</span>
          {index < value.length - 1 && (
            <span className="separator">{options?.separator}</span>
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: renderedEntry }} />
        {index < value.length - 1 && (
          <span className="separator">{options?.separator}</span>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={options?.separator !== '<br>' ? 'list-without-space' : ''}>
      {value?.length > 0 &&
        value.map((entry, index) => renderEntry(entry, index))}
    </div>
  );
};

export default Collection;
