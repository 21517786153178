import React, { useState, useEffect, useCallback } from 'react';
import Table from './Table';
import {
  fetchPanelConfig,
  fetchPanelData,
} from '../../dashboard/components/functions/dashboard-api';
import { getFieldRenderer } from './cells/getCellRenderer';
import { createMongoDBCondition } from './filterConditionsMaker';
import { useClient } from '../../react-hooks/useClient';
import { filterOperators, isFilterable } from './filters/filterOperators';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';

const ModalListTable = ({
  modalName,
  fields,
  orderBy,
  filters,
  limit,
  columnSortBy,
  ...props
}) => {
  const client = useClient();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: limit || 10,
  });
  const [loading, setLoading] = useState(false);
  const [displayView, setDisplayView] = useState('');
  const [rows, setRows] = useState([]);
  const [panelColumnsConfig, setPanelColumnsConfig] = useState([]);
  const [columnOrder, setColumnOrder] = useState(columnSortBy || []);
  const [totalRowsCount, setTotalRowCount] = useState(0);
  const [noAuth, setNoAuth] = useState(false);
  const [activeFilter, setActiveFilter] = useState(
    filters ? createMongoDBCondition(filters) : undefined
  );
  const [activeSorting, setActiveSorting] = useState(
    orderBy?.length > 0 ? orderBy : []
  );

  const reorderFields = (originalFields, newOrder) => {
    const newOrderSet = new Set(newOrder);
    const fieldsNotInNewOrder = originalFields.filter(
      (field) => !newOrderSet.has(field)
    );
    const reorderedFields = [
      ...newOrder.filter((field) => originalFields.includes(field)),
      ...fieldsNotInNewOrder,
    ];
    return reorderedFields;
  };

  const fieldWithOrder = () => {
    return reorderFields(fields, columnOrder);
  };

  const makeColumns = useCallback(() => {
    if (panelColumnsConfig?.columns?.length > 0) {
      const activeFieldsConfig = mapActiveFields(panelColumnsConfig);
      let columns = activeFieldsConfig
        .map((field) => {
          if (
            (field.visible || (field?.template && field?.filter)) &&
            fields.includes(field.property)
          ) {
            const column = {
              field: field?.property,
              headerName: field?.label,
              flex: 1,
              minWidth: 100,
              renderCell: getFieldRenderer(field),
              filterable: isFilterable(field),
              sortable: field?.sortable === false ? false : true,
              filterOperators: filterOperators(field),
            };
            if (field.type === 'boolean') {
              column.type = 'boolean';
            }
            return column;
          }
        })
        .filter((t) => t);
      if (displayView) {
        columns.push({
          field: 'actions',
          type: 'actions',
          width: 70,
          getActions: (p) => [
            <GridActionsCellItem
              icon={<OpenInNewIcon fontSize="small" />}
              label="Öffnen Sie"
              onClick={() => handleRowDoubleClick(p)}
            />,
          ],
        });
      }
      return columns;
    }
    //   console.log(columns,"columns")
  }, [panelColumnsConfig, fields, columnOrder]);

  const onPaginationModelChange = (data) => {
    setPaginationModel(data);
    if (props?.onPaginationModelChange) {
      props?.onPaginationModelChange(data?.pageSize || 10);
    }
  };

  const onFilterChange = (newFilter) => {
    console.debug(newFilter, 'table filters');
    const query = createMongoDBCondition(newFilter);
    setActiveFilter(query);
    const itemsWithValues = newFilter.items.filter((item) => {
      if (item.value) {
        return true;
      }
    });
    if (props?.onFilterChange) {
      props?.onFilterChange({ ...newFilter, items: itemsWithValues });
    }
  };

  const onColumnOrderChange = (data) => {
    const { column, oldIndex, targetIndex } = data;
    const newOrder = [...(fieldWithOrder() || [])];
    newOrder.splice(oldIndex, 1);
    newOrder.splice(targetIndex, 0, column.field);
    setColumnOrder(newOrder);
    if (props?.onColumnOrderChange) {
      props?.onColumnOrderChange(newOrder);
    }
  };

  const onSortChange = (newSortModel) => {
    setActiveSorting(newSortModel);
    if (props?.onSortChange) {
      props.onSortChange(newSortModel);
    }
  };

  const handleRowDoubleClick = (params) => {
    if (displayView) {
      window.open(`view/${displayView}?id=${params?.row?.id}`, '_blank');
    }
  };

  const mapActiveFields = (panelConfig) => {
    return fieldWithOrder()
      .map((field) => {
        const columnConfig = panelConfig.columns.find(
          (c) => c.property === field
        );
        if (columnConfig) {
          return { ...columnConfig };
        } else {
          if (field == 'id') {
            return {
              field: field,
              label: field,
              property: field,
              visible: true,
            };
          }
        }
      })
      .filter((t) => t);
  };

  const getEmbeddedFields = (activeFieldsConfig) => {
    return activeFieldsConfig.filter(
      (field) => field.type === 'reference' && typeof field?.embeds === 'string'
    );
  };

  const updateModalState = (modalData) => {
    setTotalRowCount(modalData.filteredCount);
    setRows(modalData.items);
  };

  const fetchModalData = async () => {
    setLoading(true);
    try {
      let activeFieldsConfig = [];
      if (!panelColumnsConfig.length > 0) {
        const panelConfig = await fetchPanelConfig({
          client,
          panel: modalName,
        });
        setPanelColumnsConfig(panelConfig);
        setDisplayView(panelConfig?.displayView);
      } else {
        activeFieldsConfig = panelColumnsConfig;
      }

      const embeddedFields = getEmbeddedFields(activeFieldsConfig);
      const modalData = await fetchPanelData({
        client,
        panel: modalName,
        offset: paginationModel.page,
        limit: paginationModel.pageSize,
        conditions: activeFilter,
        embeds: embeddedFields,
        sort: activeSorting.length > 0 ? activeSorting : [],
      });
      updateModalState(modalData);
      setNoAuth(false);
    } catch (error) {
      console.error('Error fetching modal data:', error);
      setNoAuth(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchModalData();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    activeFilter,
    activeSorting,
  ]);

  if (noAuth) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Sie haben keine Berechtigung, diesen Inhalt anzuzeigen.
      </Box>
    );
  }
  return (
    panelColumnsConfig?.columns?.length > 0 && (
      <>
        <Table
          rows={rows}
          columns={makeColumns()}
          onPaginationModelChange={onPaginationModelChange}
          onFilterChange={onFilterChange}
          paginationModel={paginationModel}
          rowCount={totalRowsCount}
          loading={loading}
          onSortChange={onSortChange}
          onRowDoubleClick={handleRowDoubleClick}
          onColumnOrderChange={onColumnOrderChange}
          initialState={{
            filter: { filterModel: filters },
            sorting: {
              sortModel: activeSorting,
            },
          }}
        />
      </>
    )
  );
};

export default ModalListTable;
