import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import { DateRangePicker } from '../../../form/react/fields/DateRangePicker';
import { styled } from '@mui/material/styles';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { FormControl, FormLabel } from '@mui/material';

export const DateFilter = (props) => {
  const { item, applyValue } = props;
  const onDateChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };
  return (
    <div style={{ marginTop: '16px' }}>
      <DateRangePicker
        startText="Start Date"
        endText="End Date"
        value={item.value}
        onChange={onDateChange}
        asSingleField={true}
        textFieldProps={{ variant: 'standard' }}
      />
    </div>
  );
};
