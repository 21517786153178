import React, { useState, useEffect } from 'react';
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { useUserClient } from '../../../../react-hooks/useUserClient';

const START = 0x1f1e6 - 0x41;

const PhoneDisplay = ({ value }) => {
  const [country, setCountry] = useState(null);
  const [number, setNumber] = useState(value);
  const [tel, setTel] = useState('');
  const [numberValid, setNumberValid] = useState('');
  const [emoji, setEmoji] = useState('');
  const [user] = useUserClient();

  useEffect(() => {
    const updatePhoneDisplay = async () => {
      if (!user) {
        return;
      }
      setCountry(null);
      setNumber(value);
      if (!user) {
        return;
      }
      if (value) {
        setTel('tel:' + value.replaceAll('-', ''));
      }

      const userCountry = await user?.getCountry();
      try {
        const parsed = parsePhoneNumberFromString(value, userCountry);
        const phoneCountry = parsed && parsed.country;

        if (parsed && !isValidPhoneNumber(parsed.number, parsed.country)) {
          setNumberValid('(ungültige Rufnummer)');
        }

        if (!phoneCountry) {
          return;
        }

        const userData = await user.getUser();

        if (
          userData.settings?.userSettings?.showLocalNumber &&
          phoneCountry === userCountry
        ) {
          setNumber(parsed.formatNational());
        } else {
          setCountry(phoneCountry);
          setEmoji(
            String.fromCodePoint(
              START + phoneCountry.charCodeAt(0),
              START + phoneCountry.charCodeAt(1)
            )
          );
        }
      } catch (e) {
        console.error(e);
      }
    };

    updatePhoneDisplay();
  }, [value, user]);

  return (
    <>
      <a href={tel} className="phone-number">
        {country && <abbr title={country}>{emoji}</abbr>}
        {number}
      </a>
      {numberValid}
    </>
  );
};

export default PhoneDisplay;
