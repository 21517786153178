import React, { useCallback, useState } from 'react';
import { TextField, FormControl } from '@mui/material';
import debounce from 'debounce-promise';

const TextFilter = (props) => {
  const { item, applyValue, field } = props;
  const [inputValue, setInputValue] = useState(item?.value || '');

  const debouncedApplyValue = useCallback(
    debounce((value) => {
      applyValue({ ...item, value });
    }, 500),
    [item, applyValue]
  );

  const handleChange = (event) => {
    const newValue = event?.target?.value;
    setInputValue(newValue);
    debouncedApplyValue(newValue);
  };

  return (
    <FormControl>
      <TextField
        variant="standard"
        value={inputValue}
        onChange={handleChange}
        type="text"
        size="md"
        label="Filter"
      />
    </FormControl>
  );
};

export default TextFilter;
