export const getTableTranslations = (t) => {
    return {
        noResultsOverlayLabel: t('muiTable.noResultsOverlayLabel'),
        noRowsLabel: t('muiTable.noResultsOverlayLabel'),

        toolbarDensity: t('muiTable.toolbarDensity'),
        toolbarDensityLabel: t('muiTable.toolbarDensityLabel'),
        toolbarDensityCompact: t('muiTable.toolbarDensityCompact'),
        toolbarDensityStandard: t('muiTable.toolbarDensityStandard'),
        toolbarDensityComfortable: t('muiTable.toolbarDensityComfortable'),

        toolbarColumns: t('muiTable.toolbarColumns'),
        toolbarColumnsLabel: t('muiTable.toolbarColumnsLabel'),

        toolbarFilters: t('muiTable.toolbarFilters'),
        toolbarFiltersLabel: t('muiTable.toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('muiTable.toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('muiTable.toolbarFiltersTooltipShow'),

        toolbarQuickFilterPlaceholder: t(
            'muiTable.toolbarQuickFilterPlaceholder'
        ),
        toolbarQuickFilterLabel: t('muiTable.toolbarQuickFilterLabel'),
        toolbarQuickFilterDeleteIconLabel: t(
            'muiTable.toolbarQuickFilterDeleteIconLabel'
        ),

        toolbarExport: t('muiTable.toolbarExport'),
        toolbarExportLabel: t('muiTable.toolbarExportLabel'),
        toolbarExportCSV: t('muiTable.toolbarExportCSV'),
        toolbarExportPrint: t('muiTable.toolbarExportPrint'),
        toolbarExportExcel: t('muiTable.toolbarExportExcel'),

        columnsManagementSearchTitle: t(
            'muiTable.columnsManagementSearchTitle'
        ),
        columnsManagementNoColumns: t('muiTable.columnsManagementNoColumns'),
        columnsManagementShowHideAllText: t(
            'muiTable.columnsManagementShowHideAllText'
        ),
        columnsManagementReset: t('muiTable.columnsManagementReset'),

        filterPanelAddFilter: t('muiTable.filterPanelAddFilter'),
        filterPanelRemoveAll: t('muiTable.filterPanelRemoveAll'),
        filterPanelDeleteIconLabel: t('muiTable.filterPanelDeleteIconLabel'),
        filterPanelLogicOperator: t('muiTable.filterPanelLogicOperator'),
        filterPanelOperator: t('muiTable.filterPanelOperator'),
        filterPanelOperatorAnd: t('muiTable.filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('muiTable.filterPanelOperatorOr'),
        filterPanelColumns: t('muiTable.filterPanelColumns'),
        filterPanelInputLabel: t('muiTable.filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('muiTable.filterPanelInputPlaceholder'),

        filterOperatorContains: t('muiTable.filterOperatorContains'),
        filterOperatorEquals: t('muiTable.filterOperatorEquals'),
        filterOperatorStartsWith: t('muiTable.filterOperatorStartsWith'),
        filterOperatorEndsWith: t('muiTable.filterOperatorEndsWith'),
        filterOperatorIs: t('muiTable.filterOperatorIs'),
        filterOperatorNot: t('muiTable.filterOperatorNot'),
        filterOperatorAfter: t('muiTable.filterOperatorAfter'),
        filterOperatorOnOrAfter: t('muiTable.filterOperatorOnOrAfter'),
        filterOperatorBefore: t('muiTable.filterOperatorBefore'),
        filterOperatorOnOrBefore: t('muiTable.filterOperatorOnOrBefore'),
        filterOperatorIsEmpty: t('muiTable.filterOperatorIsEmpty'),
        filterOperatorIsNotEmpty: t('muiTable.filterOperatorIsNotEmpty'),
        filterOperatorIsAnyOf: t('muiTable.filterOperatorIsAnyOf'),

        headerFilterOperatorContains: t(
            'muiTable.headerFilterOperatorContains'
        ),
        headerFilterOperatorEquals: t('muiTable.headerFilterOperatorEquals'),
        headerFilterOperatorStartsWith: t(
            'muiTable.headerFilterOperatorStartsWith'
        ),
        headerFilterOperatorEndsWith: t(
            'muiTable.headerFilterOperatorEndsWith'
        ),
        headerFilterOperatorIs: t('muiTable.headerFilterOperatorIs'),
        headerFilterOperatorNot: t('muiTable.headerFilterOperatorNot'),
        headerFilterOperatorAfter: t('muiTable.headerFilterOperatorAfter'),
        headerFilterOperatorOnOrAfter: t(
            'muiTable.headerFilterOperatorOnOrAfter'
        ),
        headerFilterOperatorBefore: t('muiTable.headerFilterOperatorBefore'),
        headerFilterOperatorOnOrBefore: t(
            'muiTable.headerFilterOperatorOnOrBefore'
        ),
        headerFilterOperatorIsEmpty: t('muiTable.headerFilterOperatorIsEmpty'),
        headerFilterOperatorIsNotEmpty: t(
            'muiTable.headerFilterOperatorIsNotEmpty'
        ),
        headerFilterOperatorIsAnyOf: t('muiTable.headerFilterOperatorIsAnyOf'),
        headerFilterOperatorIn: t('muiTable.headerFilterOperatorIn'),
        headerFilterOperatorNotIn: t('muiTable.headerFilterOperatorNotIn'),

        filterValueAny: t('muiTable.filterValueAny'),
        filterValueTrue: t('muiTable.filterValueTrue'),
        filterValueFalse: t('muiTable.filterValueFalse'),

        columnMenuLabel: t('muiTable.columnMenuLabel'),
        columnMenuShowColumns: t('muiTable.columnMenuShowColumns'),
        columnMenuManageColumns: t('muiTable.columnMenuManageColumns'),
        columnMenuFilter: t('muiTable.columnMenuFilter'),
        columnMenuHideColumn: t('muiTable.columnMenuHideColumn'),
        columnMenuUnsort: t('muiTable.columnMenuUnsort'),
        columnMenuSortAsc: t('muiTable.columnMenuSortAsc'),
        columnMenuSortDesc: t('muiTable.columnMenuSortDesc'),

        columnHeaderFiltersLabel: t('muiTable.columnHeaderFiltersLabel'),
        columnHeaderSortIconLabel: t('muiTable.columnHeaderSortIconLabel'),

        footerTotalRows: t('muiTable.footerTotalRows'),

        checkboxSelectionHeaderName: t('muiTable.checkboxSelectionHeaderName'),
        checkboxSelectionSelectAllRows: t(
            'muiTable.checkboxSelectionSelectAllRows'
        ),
        checkboxSelectionUnselectAllRows: t(
            'muiTable.checkboxSelectionUnselectAllRows'
        ),
        checkboxSelectionSelectRow: t('muiTable.checkboxSelectionSelectRow'),
        checkboxSelectionUnselectRow: t(
            'muiTable.checkboxSelectionUnselectRow'
        ),

        booleanCellTrueLabel: t('muiTable.booleanCellTrueLabel'),
        booleanCellFalseLabel: t('muiTable.booleanCellFalseLabel'),

        actionsCellMore: t('muiTable.actionsCellMore'),

        pinToLeft: t('muiTable.pinToLeft'),
        pinToRight: t('muiTable.pinToRight'),
        unpin: t('muiTable.unpin'),

        treeDataGroupingHeaderName: t('muiTable.treeDataGroupingHeaderName'),
        treeDataExpand: t('muiTable.treeDataExpand'),
        treeDataCollapse: t('muiTable.treeDataCollapse'),

        groupingColumnHeaderName: t('muiTable.groupingColumnHeaderName'),
        unGroupColumn: t('muiTable.unGroupColumn'),

        detailPanelToggle: t('muiTable.detailPanelToggle'),
        expandDetailPanel: t('muiTable.expandDetailPanel'),
        collapseDetailPanel: t('muiTable.collapseDetailPanel'),

        rowReorderingHeaderName: t('muiTable.rowReorderingHeaderName'),

        aggregationMenuItemHeader: t('muiTable.aggregationMenuItemHeader'),
        aggregationFunctionLabelSum: t('muiTable.aggregationFunctionLabelSum'),
        aggregationFunctionLabelAvg: t('muiTable.aggregationFunctionLabelAvg'),
        aggregationFunctionLabelMin: t('muiTable.aggregationFunctionLabelMin'),
        aggregationFunctionLabelMax: t('muiTable.aggregationFunctionLabelMax'),
        aggregationFunctionLabelSize: t(
            'muiTable.aggregationFunctionLabelSize'
        ),
    };
};
